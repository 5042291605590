import React from 'react';
import _ from 'lodash';
import PaymentMethod from './PaymentMethod';
import BillingData from './BillingData';
import { Box, Grid, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import Plan from './Plan';
import { useUser } from '../../../../contexts/user.context';
import { planIds } from '../../../../utils/constants';
import { Link } from 'react-router-dom';

export default function Subscription() {
    const { user } = useUser();

    const isTray = user?.store?.platformId == 'tray';
    const isTrayPro = [planIds.PLAN_PRO_MONTHLY_2023, planIds.PLAN_PRO_MONTHLY_2024].includes(user?.subscription?.planId);

    return (
        !_.isEmpty(user?.subscription) ? (
            (isTray && isTrayPro) ? (
                <Grid container direction="row" spacing={4}>
                    <Grid display='flex' item xs={12} md={12}>
                        <Plan isTray />
                    </Grid>
                </Grid>
            ) : (
                <Grid container direction="row" spacing={4}>
                    <Grid display='flex' item xs={12} md={6}>
                        <Plan />
                    </Grid>
                    <Grid display='flex' item xs={12} md={6}>
                        <PaymentMethod />
                    </Grid>
                    <Grid item xs={12}>
                        <BillingData />
                    </Grid>
                </Grid>
            )
        ) : (
            <Box textAlign={'center'} mt={2}>
                <PaidIcon />
                <Typography variant="body1" align="center">
                    Você ainda não tem uma assinatura ativa.
                </Typography>
                <Typography variant="body1" align="center" mt={0.5}>
                    <Link variant='body2' to='/pricing'>Escolha seu plano</Link> e publique o aplicativo da sua loja!
                </Typography>
            </Box>
        )
    );
}