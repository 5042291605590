import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Card, Grid, Modal, Paper, CircularProgress } from "@mui/material";
import MaterialTable from "@material-table/core";
import _ from 'lodash';
import useAppInfo from '../hooks/appInfo.hook';
import { categoriesServices } from '../services/categories.service'
import { flatMappedCategories } from '../utils/mappers/nuvemshop';

const useStyles = makeStyles(() => ({
    button: {
        marginTop: 22,
        width: '100%'
    },
    modal: {
        position: 'absolute',
        width: '70%',
        padding: 0,
        top: '50%',
        left: '50%',
        maxHeight: '80%',
        overflowY: 'auto',
        transform: 'translate(-50%, -50%)'
    },
    modalButton: {
        margin: 20
    },
    table: {
        boxShadow: 'none'
    },
    label: {
        display: "block",
        marginBottom: -15,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: 1.8,
        color: "#000"
    }
}));

export default function CategoryPicker({ onPicked, selectedCategoryId }) {
    const { appInfo } = useAppInfo()

    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);

    const [flattenedCategories, setFlattenedCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        categoriesServices.getAllCategories(appInfo.platformId, appInfo.storeId, appInfo.accessToken)
            .then(response => {
                const fmc = flatMappedCategories(response);
                setFlattenedCategories(fmc);

                if(!!selectedCategoryId) {
                    setSelectedCategory(fmc.find(c => c.id === selectedCategoryId))
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);

    const handleRowClick = (data) => {
        setShowModal(false);
        setSelectedCategory(data);

        if (onPicked) {
            onPicked(data)
        }
    }

    return (
        <div>
            <Grid container>
                <label className={classes.label}>Categoria</label>
                <Button
                    className={classes.button}
                    onClick={() => setShowModal(true)}
                    variant="outlined"
                    color="secondary"
                >
                    {loading ?
                        <CircularProgress size={20} /> :
                        (_.isEmpty(selectedCategory) ? 'Selecionar categoria' : selectedCategory.breadcrumb)
                    }
                </Button>
            </Grid>

            <Modal
                disableAutoFocus
                disableEnforceFocus
                onClose={() => setShowModal(false)}
                open={showModal}
            >
                <Card className={classes.modal}>
                    <MaterialTable
                        columns={[
                            { title: 'Categoria', field: 'breadcrumb' }
                        ]}
                        components={{
                            Container: props => <Paper {...props} elevation={0} />
                        }}
                        data={flattenedCategories}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}'
                            },
                            toolbar: {
                                searchPlaceholder: 'Buscar',
                                searchTooltip: 'Buscar'
                            },
                            body: {
                                emptyDataSourceMessage: 'Sem categorias para exibir'
                            }
                        }}
                        isLoading={loading}
                        onRowClick={(e, data) => handleRowClick(data)}
                        options={{
                            pageSize: 5,
                            pageSizeOptions: [],
                            header: false
                        }}
                        title="Categorias"
                    />
                    <Grid container justifyContent='center'>
                        <Button
                            color="secondary"
                            className={classes.modalButton} onClick={() => setShowModal(false)}
                            variant='outlined'
                        >
                            Cancelar
                        </Button>
                    </Grid>
                </Card>
            </Modal>
        </div>
    );
}