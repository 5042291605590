import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import _ from "lodash";
import { Box, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { formatIsoTimestamp, getInvoiceStatusChip, getPaymentMethodLabel, mapIntegerInCentsToCurrency } from '../../../../utils/mappers';
import { mobilizeiSubscriptionsService } from '../../../../services/mobilizei/subscriptions.service';
import { showApiError } from '../../../../utils/errors';
import { useUser } from '../../../../contexts/user.context';

export default function InvoicesHistory() {
    const { user } = useUser();

    const [ isLoading, setIsLoading ] = useState(true);
    const [ invoices, setInvoices ] = useState([]);

    useEffect(() => {
        getInvoices();
    }, [])

    const getInvoices = () => {
        if(!user?.subscription?.id){
            setIsLoading(false);
            return;
        }

        setIsLoading(true)

        mobilizeiSubscriptionsService.getInvoices(user?.subscription?.id).then((response) => {
            setInvoices(response)
        })
        .catch(e => {
            showApiError(e.error);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    if(isLoading){
        return <Box mb={6}>
            <Box display='flex' alignItems='center' justifyContent='center' mt={2}>
                <CircularProgress />
            </Box>
        </Box>
    }
    
    return (
        <Box mb={6}>
            {_.isEmpty(invoices) ? (
                <Box textAlign={'center'} mt={2}>
                    <ReceiptLongIcon />
                    <Typography variant="body1" align="center">
                        Você ainda não possui nenhuma fatura disponível.
                    </Typography>
                </Box>
            ) : (
                <>
                    <Typography variant="h5" mb={2}>
                        Histórico de faturas
                    </Typography>

                    <TableContainer >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Vencimento</TableCell>
                                    <TableCell align="center">Data de Pagamento</TableCell>
                                    <TableCell align="center">Valor</TableCell>
                                    <TableCell align="center">Método de Pagamento</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.map((invoice) => (
                                    <TableRow key={invoice.id}>
                                        <TableCell align="center">{formatIsoTimestamp(invoice.dueDate, 'DD/MM/YYYY') || '-'}</TableCell>
                                        <TableCell align="center">{formatIsoTimestamp(invoice.paidAt, 'DD/MM/YYYY') || '-'}</TableCell>
                                        <TableCell align="center">{mapIntegerInCentsToCurrency(invoice.totalInCents) || '-'}</TableCell>
                                        <TableCell align="center">{getPaymentMethodLabel(invoice.paymentMethod) || '-'}</TableCell>
                                        <TableCell align="center">{getInvoiceStatusChip(invoice.status)}</TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Ver fatura">
                                                <IconButton onClick={() => window.open(`/faturas/${invoice.id}`, "_blank")}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    );
}