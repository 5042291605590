import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useUser } from '../../../../contexts/user.context';
import { planPeriodicity, plans, subscriptionStatus } from '../../../../utils/constants';
import { formatIsoTimestamp, getSubscriptionStatusChip } from '../../../../utils/mappers';
import { mobilizeiSubscriptionsService } from '../../../../services/mobilizei/subscriptions.service';
import SubscriptionCancelDialog from './SubscriptionCancelDialog';
import { toast } from 'react-toastify';
import SubscriptionActivateDialog from './SubscriptionActivateDialog';

export default function Plan({ isTray }) {
    const { user, setUser } = useUser();
    const [plan, setPlan] = useState(null);

    const [isSubscriptionActivateDialogOpen, setIsSubscriptionActivateDialogOpen] = useState(false);
    const [isSubscriptionCancelDialogOpen, setIsSubscriptionCancelDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (user.subscription?.planId) {
            setPlan(plans[user.subscription.planId]);
        }
    }, []);

    const getStatusMessage = (status) => {
        switch (status) {
            case subscriptionStatus.PENDING:
                return 'Efetue o pagamento da fatura em aberto para ativar a sua assinatura.'
            case subscriptionStatus.PENDING_SUSPENSION:
                return `Sua assinatura foi cancelada e permanecerá ativa até o dia ${formatIsoTimestamp(user.subscription?.expiresAt, 'DD/MM/YYYY')}. Não serão geradas nenhuma nova fatura ou cobrança.`
            case subscriptionStatus.EXPIRED:
                return 'Efetue o pagamento da fatura em aberto para evitar o cancelamento da sua assinatura.'
            case subscriptionStatus.SUSPENDED:
                return 'Sua fatura foi cancelada e não serão geradas nenhuma nova fatura ou cobrança.'
            default:
                return '';
        }
    }

    const onCancelSubscription = () => {
        if (!user?.subscription?.id) {
            return
        }

        setIsSubscriptionCancelDialogOpen(false);
        setIsLoading(true);

        mobilizeiSubscriptionsService.suspend(user?.subscription?.id)
            .then(() => {
                toast.success('Assinatura cancelada com sucesso!');

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({
                    ...user, subscription: {
                        ...user?.subscription,
                        status: subscriptionStatus.PENDING_SUSPENSION
                    }
                });
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível cancelar a assinatura. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const onActivateSubscription = () => {
        if (!user?.subscription?.id) {
            return
        }

        setIsSubscriptionActivateDialogOpen(false);
        setIsLoading(true);

        mobilizeiSubscriptionsService.activate(user?.subscription?.id)
            .then(() => {
                toast.success('Assinatura reativada com sucesso!');

                // Update the local user data to match the data that was 
                // updated on the backend
                setUser({
                    ...user, subscription: {
                        ...user?.subscription,
                        status: subscriptionStatus.ACTIVE
                    }
                });
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível reativar a assinatura. Por favor, tente novamente mais tarde. (#${errorCode})`);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return (
        <Box display='flex' flexDirection='column' width='100%'>
            <Typography variant="h5" mb={2}>
                Sua assinatura
            </Typography>

            {isLoading ? (
                <Card
                    elevation={2}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center',
                        padding: 1
                    }}
                >
                    <CircularProgress />
                </Card>
            ) : (
                <Card
                    elevation={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center',
                        padding: 1
                    }}
                >
                    <CardContent sx={{ paddingX: 4 }}>
                        <Grid container direction="row" spacing={1}>
                            <Grid item xs={5} sm={6}>
                                <Typography variant='body1'>
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sm={6}>
                                <Typography variant='body1' color={'grey'}>
                                    {getSubscriptionStatusChip(user.subscription?.status)}
                                </Typography>
                            </Grid>

                            <Grid item xs={5} sm={6}>
                                <Typography variant='body1'>
                                    Plano
                                </Typography>
                            </Grid>
                            <Grid item xs={7} sm={6}>
                                <Typography variant='body1' color={'grey'}>
                                    {plan?.name || '-'}
                                </Typography>
                            </Grid>

                            {!isTray &&
                                <>
                                    <Grid item xs={5} sm={6}>
                                        <Typography variant='body1'>
                                            Valor
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={6}>
                                        <Typography variant='body1' color={'grey'}>
                                            {plan?.totalPrice ?
                                                `R$ ${plan?.totalPrice},00 / ${plan?.periodicity === planPeriodicity.YEARLY ? 'ano' : 'mês'}` :
                                                '-'
                                            }
                                        </Typography>
                                    </Grid>
                                </>
                            }

                            {!isTray && user.subscription?.status === subscriptionStatus.ACTIVE &&
                                <>
                                    <Grid item xs={5} sm={6}>
                                        <Typography variant='body1'>
                                            Próxima cobrança
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={6}>
                                        <Typography variant='body1' color={'grey'}>
                                            {formatIsoTimestamp(user.subscription?.expiresAt, 'DD/MM/YYYY') || '-'}
                                        </Typography>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <Typography variant='body1' color={'grey'} fontStyle={'italic'}>
                                    {getStatusMessage(user.subscription?.status)}
                                </Typography>
                            </Grid>

                            {user.subscription?.isAlsoChargedOnTray &&
                                <Grid item xs={12}>
                                    <Typography variant='body1' color={'grey'} fontStyle={'italic'}>
                                        Valores adicionais podem ser cobrados diretamente pela Tray, junto com a fatura da
                                        sua loja na plataforma. Acesse a área de{' '}
                                        <a
                                            href={`http://api.minhatray.tray.com.br/api/auth?store=${user.store?.id}&url=https://${user.store?.id}.commercesuite.com.br`}
                                            target='_blank'
                                        >
                                            Minhas Faturas
                                        </a>
                                        {' '}do painel administrativo da Tray para ver mais detalhes.
                                    </Typography>
                                </Grid>
                            }

                            {isTray &&
                                <Grid item xs={12}>
                                    <Typography variant='body1' color={'grey'} fontStyle={'italic'}>
                                        O valor mensal da sua assinatura Mobilizei é cobrado diretamente pela Tray, junto com a
                                        fatura da sua loja na plataforma. Acesse a área de{' '}
                                        <a
                                            href={`http://api.minhatray.tray.com.br/api/auth?store=${user.store?.id}&url=https://${user.store?.id}.commercesuite.com.br`}
                                            target='_blank'
                                        >
                                            Minhas Faturas
                                        </a>
                                        {' '}do painel administrativo da Tray para ver mais detalhes.
                                    </Typography>
                                </Grid>
                            }

                        </Grid>
                    </CardContent>
                    <CardActions disableSpacing sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: 0
                    }}>

                        {!isTray && (user.subscription?.status === subscriptionStatus.ACTIVE ||
                            user.subscription?.status === subscriptionStatus.EXPIRED) &&
                            <Button onClick={() => setIsSubscriptionCancelDialogOpen(true)} size="small" sx={{
                                color: 'red',
                                ':hover': { bgcolor: 'transparent' }
                            }}>
                                Cancelar
                            </Button>
                        }

                        {!isTray && user.subscription?.status === subscriptionStatus.PENDING_SUSPENSION &&
                            <Button onClick={() => setIsSubscriptionActivateDialogOpen(true)} size="small" sx={{
                                color: 'red',
                                ':hover': { bgcolor: 'transparent' }
                            }}>
                                Reativar
                            </Button>
                        }

                    </CardActions>
                </Card>
            )}

            <SubscriptionCancelDialog
                isOpen={isSubscriptionCancelDialogOpen}
                setIsOpen={setIsSubscriptionCancelDialogOpen}
                onConfirm={() => onCancelSubscription()}
                expiresAt={formatIsoTimestamp(user.subscription?.expiresAt, 'DD/MM/YYYY')}
            />

            <SubscriptionActivateDialog
                isOpen={isSubscriptionActivateDialogOpen}
                setIsOpen={setIsSubscriptionActivateDialogOpen}
                onConfirm={() => onActivateSubscription()}
            />
        </Box>
    );
}