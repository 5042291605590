import { useEffect, useState } from 'react'
import { isTokenExpired } from '../utils/auth'
import { APP_AUTH_TOKEN } from '../utils/constants'
import { useUser } from '../contexts/user.context';
import { mobilizeiUsersService } from '../services/mobilizei/users.service';
import { useOnboarding } from '../contexts/onboarding.context';
import { usePlan } from '../contexts/plan.context';
import mixpanel from 'mixpanel-browser';

export default function useAuthentication() {
    const [isLoading, setIsLoading] = useState(true)

    const [token, setToken] = useState()
    const { user, setUser } = useUser()

    const urlParams = new URLSearchParams(window.location.search)
    const urlToken = urlParams.get('at');

    const localStorageToken = localStorage.getItem(APP_AUTH_TOKEN);

    const { clearOnboardingContext } = useOnboarding();
    const { clearPlanContext } = usePlan();

    const fetchUser = () => {
        if (!token) return;

        mobilizeiUsersService.getSelf()
            .then(fetchedUser => {
                setUser(fetchedUser);
                window.clarity("identify", fetchedUser.email);
                mixpanel.identify(fetchedUser.id);
            })
            .catch(() => {
                setUser(null);
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const logout = () => {
        // Clear user, contexts and token data
        setUser(null);
        clearOnboardingContext();
        clearPlanContext();
        localStorage.removeItem(APP_AUTH_TOKEN);
        mixpanel.reset();
    }

    useEffect(() => {
        if (token) {
            localStorage.setItem(APP_AUTH_TOKEN, token)

            //verify token validity
            const tokenExpired = isTokenExpired(token)

             //logout when token expire
            if(tokenExpired){
                return logout();
            }

            if(!user || !user.store){
                fetchUser()
            }
        }
    }, [token])

    useEffect(() => {
        // Try to get the access token from the url or the local storage
        const accessToken = urlToken || localStorageToken;

        // Check if the access token is valid
        if (accessToken && isTokenExpired(accessToken)) {
            logout();
        } else if (accessToken) {
            setToken(accessToken);
            return;
        }

        setIsLoading(false)
    }, [])

    return {
        isAuthLoading: isLoading,
        logout,
        token,
        setToken
    }
}
