import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { mobilizeiInvoicesService } from '../../../services/mobilizei/invoices.service';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import CreditCardDataForm from '../../../components/billing/CreditCardDataForm';
import BankSlipData from '../../../components/billing/BankSlipData';
import PixData from '../../../components/billing/PixData';
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    paymentMethodWrapper: {
        alignItems: 'center',
        border: '2px solid #C6C6C6',
        borderRadius: 8,
        color: '#C6C6C6',
        cursor: 'pointer',
        display: 'flex',
        height: 150,
        justifyContent: 'center',
        textAlign: 'center',
        width: 150,
        '@media (max-width: 600px)': {
            height: 'auto',
            justifyContent: 'flex-start',
            margin: '10px 0',
            padding: '5px 30px',
            width: '100%'
        },
        "& img": {
            width: 75,
            '@media (max-width: 600px)': {
                width: 40
            }
        },
        "& p": {
            fontSize: 16,
            margin: 0,
            '@media (max-width: 600px)': {
                marginLeft: 10
            }
        },
        "&.selected": {
            border: '2px solid #3cd568',
            color: '#3cd568',
            fontWeight: 'bold'
        }
    },
    paymentMethodContent: {
        '@media (max-width: 600px)': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row'
        }
    }
}));

export default function PaymentMethods({ invoice, setInvoice }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const { setValue, watch } = useForm({
        defaultValues: {
            paymentMethod: 'credit_card',
            creditCardToken: '',
            creditCard: {
                number: '',
                cvv: '',
                name: '',
                expiration: ''
            }
        }
    });

    const watchPaymentMethod = watch('paymentMethod', 'credit_card');

    async function payWithCreditCard(creditCardToken) {
        setLoading(true);

        await mobilizeiInvoicesService.charge(creditCardToken, invoice.id)
            .then(() => {
                setInvoice({
                    ...invoice,
                    status: 'paid',
                    paymentMethod: 'credit_card',
                    paidAt: (new Date()).toISOString()
                })
                toast.success('Pagamento realizado com sucesso!');
                setLoading(false);
            })
            .catch(error => {
                const errorCode = _.get(error, 'error.code', '');
                toast.error(`Não foi possível realizar o pagamento da fatura. Por favor, confira os seus dados e tente novamente. (#${errorCode})`)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const PaymentMethodOption = ({ label, id }) => (
        <Grid
            item
            onClick={() => setValue('paymentMethod', id)}
            sm={4}
            xs={12}
        >
            <Box alignItems="center" display="flex" justifyContent="center" >
                <div className={clsx(classes.paymentMethodWrapper, watchPaymentMethod === id && 'selected')}>
                    <div className={classes.paymentMethodContent}>
                        {watchPaymentMethod === id ?
                            <img src={require(`../../../assets/img/billing/${id}_selected.png`)} alt="logo" /> :
                            <img src={require(`../../../assets/img/billing/${id}.png`)} alt="logo" />
                        }
                        <p>{label}</p>
                    </div>
                </div>
            </Box>
        </Grid>
    )

    return (
        <Box mb={4}>
            <Grid container direction="row" alignItems="center" spacing={2}>

                <Grid item xs={12} mb={2}>
                    <Typography variant="h5">
                        {invoice?.payableWith?.length > 1 ? 'Escolha seu método de pagamento' : 'Efetue o pagamento'}
                    </Typography>
                </Grid>

                {invoice?.payableWith?.length > 1 &&
                    <Grid container direction="row" spacing={2} justifyContent="space-between">
                        {invoice?.payableWith?.includes('credit_card') && <PaymentMethodOption label="Cartão de crédito" id="credit_card" />}
                        {invoice?.payableWith?.includes('bank_slip') && <PaymentMethodOption label="Boleto" id="bank_slip" />}
                        {invoice?.payableWith?.includes('pix') && <PaymentMethodOption label="Pix" id="pix" />}
                    </Grid>
                }

                <Box my={6}>
                    {watchPaymentMethod === 'credit_card' &&
                        <CreditCardDataForm
                            watch={watch}
                            loading={loading}
                            primaryButtonAction={payWithCreditCard}
                            primaryButtonLabel={'Pagar com cartão de crédito'}
                            setValue={setValue}
                            setLoading={setLoading}
                        />
                    }
                    {watchPaymentMethod === 'bank_slip' &&
                        <BankSlipData
                            text="O pagamento via boleto bancário poderá levar cerca de 3 dias úteis para ser compensado. Te manteremos informado a respeito do status do pagamento através do seu e-mail cadastrado."
                            invoice={invoice}
                        />
                    }
                    {watchPaymentMethod === 'pix' &&
                        <PixData
                            invoice={invoice}
                        />
                    }
                </Box>
            </Grid>
        </Box>


    );
}